import { useModel } from '@umijs/max';
import { useEffect, useRef, useState } from 'react';
import {getMenus} from "@/services/blog/menu";

const extendMethod = (item: any, list: any) => {
    let it = item;
    it.getOption = () => it;
    it.getLabel = () => it.label;
    it.getValue = () => it.value;
    it.findItemByLabel = (label: string) => {
        return list.find((v: any) => v['label'] == label);
    };
    it.findItemByValue = (value: string | number) => {
        return list.find((v: any) => v['value'] == value);
    };
    return it;
};

const optionsTransform = (list: any, options?: { label: string; value: string; children?: string }) => {
    const newList = list.map((v: any) => {
        if (options) {
            v.label = v[options.label];
            v.value = v[options.value];

            if (v[options.children!] || v.children) {
                const result = optionsTransform(v[options.children!] || v.children, options);
                if (result.length) {
                    v.children = result;
                }
            } else {
                v[options.children!] = [];
            }
        }

        return extendMethod(v, list);
    });
    newList.getOption = (value: any) => newList.findItemByValue(value);
    newList.getLabel = (value: any) => newList.findItemByValue(value)?.label;
    newList.getValue = (value: any) => newList.findItemByValue(value)?.value;
    newList.findItemByLabel = (label: string) => {
        return newList.find((v: any) => v['label'] == label);
    };
    newList.findItemByValue = (value: string | number) => {
        return newList.find((v: any) => v['value'] == value);
    };
    return newList;
};

export { optionsTransform };

export default () => {
    const [configObj, setConfigObj] = useState<any>({});
    const [menus, setMenus] = useState<any[]>([])

    useEffect(()=>{
        (async ()=>{
            const { data } = await getMenus()
            setMenus(data || [])
        })()
    }, [])

    // const getDictConfig = async () => {
        // const { data } = await getOptionSelect();
        // return Object.keys(data).reduce<Record<string, any>>((t, c) => {
        //     const list = (data[c] || []).map((v: any)=> {
        //         try {
        //             v.value = Number(v.value)
        //         } catch(e) {
        //         }
        //         return v
        //     })
        //     t[c] = optionsTransform(list, { label: 'label', value: 'value' });
        //     return t;
        // }, {});
    // };




    return {
        menus,
        configObj,
    };
};

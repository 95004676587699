import icons from './icons'
import './index.css'
export const RenderIcon = (props: {iconName: string, style?: React.CSSProperties, className?: string, onClick?: () => void}) => {

    const { iconName, className,style, onClick } = props

        // @ts-ignore
    const IconComp = icons[iconName]
    return IconComp ? <IconComp onClick={onClick} style={style} className={className} /> :  <span />
}

/*
 * @Author: guoqiao
 * @Date: 2022-09-30
 * @Description:cookie简单封装
 */
import cookies, { CookieAttributes } from 'js-cookie';

export const getCookie = (key: string) => {
    return cookies.get(key);
};

export const setCookie = (key: string, value: string, options: CookieAttributes) => {
    cookies.set(key, value, options);
};

export const delCookie = (key: string) => {
    cookies.remove(key);
};

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getToken } from '@/utils/authToken';
import message from "@/components/Message";
interface dataParms<T> {
    data: T;
    resultCode: number;
    expandMap: Record<string, any>
    page?: {
        current: number
        total: number
        size: number
        totalPage: number
    }
    success: string;
    msg: string;
}
// axios基本配置
const baseConfig = {
    withCredentials: true, // 允许跨域
    timeout: 20 * 1000, // 超时设置10S
    retry: 2, // 超时重新发请求的次数
    retryDelay: 200, // 超时后再次发请求的间隔时间
    retryCount: 0,
};

export const getTokenHeader = () => {
    return {
        token: getToken(),
        Authorization: 'Bearer ' + getToken(),
    };
};
/**
 * axios超时判断
 */
const isTimeoutError = (err: AxiosError) => err.code === 'ECONNABORTED' && err.message.includes('timeout');

/**
 * axios非超时,错误处理
 */

const commonErrorHandler = (err: any, url?: string) => {
    console.log(err, 'err');
    if (axios.isCancel(err)) {
        // notification.info({
        //     duration: 3,
        //     message: `${url},请求已经取消`,
        //     description: err!.message,
        // });
    } else {
        console.log(err.response);

        if (err.response?.status === 502) {
            message.error('系统内部错误');
            return;
        }

        if (err.response?.data?.resultCode === 110004) {
            message.warn(err.response?.data?.msg);
            // return history.push('/login');
        }

        // notification.error({
        //     duration: 8,
        //     message: `${url},请求出错`,
        //     description: `${err.response?.data?.msg || ''} `, // 超时信息会在err.message
        // });

        const msg = err.response?.data?.msg || err.response?.data?.message;
    }

    return { data: null };
};

/**
 * 统一错误处理
 * @param  {AxiosError} err
 * @param url
 * @description: 和后端统一返回数据类型：{ data: {}, error: ''},请求不符合条件，返回状态码后端要进行相应的设置，并设置error对应的信息
 */
const errorHandler = (err: AxiosError, url?: any): any => {
    // eslint-disable-next-line no-use-before-define
    return isTimeoutError(err) ? timeoutHandle(err, url) : commonErrorHandler(err, url);
};

/**
 * axios超时,错误处理
 */
const timeoutHandle = async (err: AxiosError, url: any) => {
    const { config }: any = err;
    config.retryCount = config.retryCount || 0;
    if (config.retryCount >= baseConfig.retry) {
        commonErrorHandler(err, url);
        config.retryCount = 0;
        return Promise.resolve({ data: '' });
    }
    config.retryCount += 1;
    return axios(config).catch(err => errorHandler(err, url));
};

/**
 * 请求做统一错误拦截，提醒
 * @param  {object} option ： 请求参数
 */
const cancelQueue: Array<Function> = [];

const cancel = (text?: any) => {
    if (!cancelQueue.length) return;
    for (let i = 0; i < cancelQueue.length; i++) {
        cancelQueue[i](text);
    }
};
const request = async ({
    isAvoidShowError,
    isCheckToken = true,
    successMsg,
    loadingText,
    ...option
}: AxiosRequestConfig & { isAvoidShowError?: boolean } & { isCheckToken?: boolean } & { successMsg?: string } & {
    loadingText?: string;
    cancelToken?: (fuc: Function) => void;
}): Promise<any> => {
    const cancelToken = new axios.CancelToken((c: Function) => {
        cancelQueue.push(c);
        option?.cancelToken?.(c);
    });
    const token = getToken();
    if (isCheckToken && !token) {
        //校验token,且没有token的情况下会自动跳回 默认校验token
        // history.push('/login');
    }
    // if (loadingText) message.loading(loadingText);
    option.headers = Object.assign(getTokenHeader(), option.headers);
    let options = {
        ...option,
        url: (process.env.REMOTE_PREFIX || "") + option.url, //根据环境重写URL
    };

    const result = await axios({ ...baseConfig, ...options, cancelToken }).catch(err => errorHandler(err, option!.url));
    if (!result?.data) return Promise.reject();
    const { resultCode, msg, success } = result?.data;

    if (isAvoidShowError) return +resultCode === 0 ? Promise.resolve(result?.data) : Promise.reject(result?.data);
    // 是否显示错误，true不显示
    // console.log(resultCode, 'resultCode')
    //暂时注释错误提示
    if (+resultCode === 401) {
        // history.push('/login');
        return Promise.reject(result?.data)
    }

    if(!success){
        message.error(msg || '请求出错', 2);
        return Promise.reject(result?.data)
    }

    if (+resultCode !== 0 && token && msg) {
        message.error(msg || '请求出错', 2);
        return Promise.reject(result?.data)
    } else {
        if (successMsg) message.success(successMsg, 2);
    }
    return Promise.resolve(result?.data);
};

/**
 * get请求
 * @param  {string} url: 请求路径
 * @param  {object} params ： 请求参数~
 * @param  {object} options : 自定义配置
 * @param isAvoidShowError ：是否显示错误
 * @param successMsg ：请求成功后弹出的提示
 * @param loadingText ：请求过程中的loading
 * @param isCheckToken ：是否校验token  /如获取验证码  登录 接口无需校验token
 */
const axiosGet = async <T = any>(
    url: string,
    data?: object,
    isCheckToken?: boolean,
    successMsg?: string,
    loadingText?: string,
    options?: AxiosRequestConfig & { cancelToken?: (fuc: Function) => void },
    isAvoidShowError?: boolean,
): Promise<dataParms<T>> =>
    request({
        url,
        isAvoidShowError,
        params: data,
        ...options,
        successMsg,
        loadingText,
        isCheckToken,
    });

/**
 * post请求1
 * @param  {string} url: 请求路径
 * @param  {object} params ： 请求参数~
 * @param {boolean} isCheckToken ：是否校验token  默认为true/如获取验证码  登录 接口无需校验token
 * @param {string} successMsg ：请求成功后弹出的提示
 * @param {string} loadingText ：请求过程中的loading
 * @param  {object} options : 自定义配置
 * @param {boolean} isAvoidShowError ：是否显示错误
 * @description: post数据为application/json，一般用于新增,建议都用JSON传数据，可以比较方便的表示更为复杂的结构（有嵌套对象）文件对象另写包装方法
 */
const axiosPost = async <T = any>(
    url: string,
    data: object,
    isCheckToken?: boolean,
    successMsg?: string,
    loadingText?: string,
    options?: AxiosRequestConfig & { cancelToken?: (fuc: Function) => void },
    isAvoidShowError?: boolean,
): Promise<dataParms<T>> =>
    request({
        url,
        isAvoidShowError,
        data,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        ...options,
        successMsg,
        loadingText,
        isCheckToken,
    });

/**
 * put请求
 * @param  {string} url: 请求路径
 * @param  {object} data ： 请求参数~
 * @param isCheckToken ：是否校验token  /如获取验证码  登录 接口无需校验token
 * @param isAvoidShowError ：是否显示错误
 * @param successMsg ：请求成功后弹出的提示
 * @param loadingText ：请求过程中的loading
 * @param  {object} options : 自定义配置
 * @description:用于更数据，需要提交整个对象
 */
const axiosPut = async <T>(
    url: string,
    data: object,
    isCheckToken?: boolean,

    successMsg?: string,
    loadingText?: string,
    options?: AxiosRequestConfig & { cancelToken?: (fuc: Function) => void },
): Promise<dataParms<T>> =>
    request({
        url,
        data,
        method: 'put',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        ...options,
        successMsg,
        loadingText,
        isCheckToken,
    });
/**
 * delete请求
 * @param  {string} url: 请求路径
 * @param  {object} params ： 请求参数~
 * @param  {object} options : 自定义配置
 * @param isAvoidShowError ：是否显示错误
 * @param successMsg ：请求成功后弹出的提示
 * @param loadingText ：请求过程中的loading
 * @param isCheckToken ：是否校验token  /如获取验证码  登录 接口无需校验token
 * @description:用于删除数据
 */
const axiosDelete = async <T>(
    url: string,
    data: object,
    isCheckToken?: boolean,
    successMsg?: string,
    loadingText?: string,
    options?: AxiosRequestConfig & { cancelToken?: (fuc: Function) => void },
): Promise<dataParms<T>> =>
    request({
        url,
        data,
        method: 'delete',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        ...options,
        successMsg,
        loadingText,
        isCheckToken,
    });

const myAll = (promiseArr: Promise<any>[], number?: number) => {
    // 为了让传入的值不是promise也返回promise
    return new Promise((resolve, reject) => {
        if (!Array.isArray(promiseArr)) {
            throw new Error('promiseArr必须为数组');
        }
        const resArr: Array<Function> = [];
        const len = promiseArr.length;
        let count = 0;
        for (let i = 0; i < len; i++) {
            // Promise.resolve将数组中非promise转为promise
            Promise.resolve(promiseArr[i])
                // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
                .then(value => {
                    count++;
                    if (value) resArr[i] = value;
                    if (count === number) {
                        cancel();
                        return resolve(resArr);
                    }
                    resolve(resArr);
                })
                .catch(err => {
                    return reject(err);
                });
        }
    });
};
const axiosAll = async (fetch: Promise<any>[], completeNumber?: number): Promise<any> => {
    const data = await myAll(fetch, completeNumber).catch(errorHandler);

    return data;
};

export { axiosGet, axiosPost, axiosPut, axiosDelete, axiosAll, errorHandler, cancel };

const config = {
  title: '神代緋桜',
  author: '神代緋桜',
  subTitle: '时光于此刻倦写成诗',
  userCode: '444',
  url: 'http://blog.kotomi.cc',
  github: {
    username: 'senjyouhara',
    repository: 'posts',
    token: ['ghp_8RV453OroiECaz0XP', 'pecgHuTxL39Pv0W00Kp'],
  },
  leancloud: {
    appId: 'LjOwR4ELEqbauKvtQbwq5TUw-MdYXbMMI',
    appKey: 'BOSFJ49ohbGctgn4zvJE9VN6',
  },
  contact: {
    github: 'https://github.com/senjyouhara',
    twitter: '',
    telegram: '',
    email: '',
    music: '',
    blog: '',
  },
  gitalk: {
    clientID: 'b88c223c8715881c7db5',
    clientSecret: 'c0f5153945875709168d1b0118947becc69d4f66',
    repo: 'comment',
    owner: 'senjyouhara',
    admin: ['senjyouhara'],
    distractionFreeMode: false,
  },
}

export default config

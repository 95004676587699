import { ReactComponent as Villa } from '@/assets/icons/villa.svg'
import { ReactComponent as Inbox } from '@/assets/icons/inbox.svg'
import { ReactComponent as Bookmark } from '@/assets/icons/bookmark.svg'
import { ReactComponent as Tag } from '@/assets/icons/tag.svg'
import { ReactComponent as Book } from '@/assets/icons/book.svg'
import { ReactComponent as Moon } from '@/assets/icons/moon.svg'
import { ReactComponent as Message } from '@/assets/icons/message.svg'
import { ReactComponent as Heart } from '@/assets/icons/heart.svg'
import { ReactComponent as User } from '@/assets/icons/user.svg'
import { ReactComponent as Github } from '@/assets/icons/github.svg'
import { ReactComponent as Twitter } from '@/assets/icons/twitter.svg'
import { ReactComponent as Telegram } from '@/assets/icons/telegram.svg'
import { ReactComponent as Music } from '@/assets/icons/music.svg'
import { ReactComponent as Mail } from '@/assets/icons/mail.svg'
import { ReactComponent as Anchor } from '@/assets/icons/anchor.svg'
import { ReactComponent as External } from '@/assets/icons/external.svg'
import { ReactComponent as Hash } from '@/assets/icons/hash.svg'
import { ReactComponent as Search } from '@/assets/icons/search.svg'
import { ReactComponent as Scissors } from '@/assets/icons/scissors.svg'
import { ReactComponent as Send } from '@/assets/icons/send.svg'
import { ReactComponent as Code } from '@/assets/icons/code.svg'
import { ReactComponent as Calendar } from '@/assets/icons/calendar.svg'
import { ReactComponent as Eye } from '@/assets/icons/eye.svg'
import { ReactComponent as Thunder } from '@/assets/icons/thunder.svg'
import { ReactComponent as Rain } from '@/assets/icons/rain.svg'
import { ReactComponent as Snow } from '@/assets/icons/snow.svg'
import { ReactComponent as Sun } from '@/assets/icons/sun.svg'
import { ReactComponent as Sunshine } from '@/assets/icons/sunshine.svg'
import { ReactComponent as Windy } from '@/assets/icons/windy.svg'
import { ReactComponent as CheckCircle } from '@/assets/icons/check-circle.svg'
import { ReactComponent as CloseCircle } from '@/assets/icons/close-circle.svg'
import { ReactComponent as ExclamationCircle } from '@/assets/icons/exclamation-circle.svg'
import { ReactComponent as InfoCircle } from '@/assets/icons/info-circle.svg'
import { ReactComponent as Like } from '@/assets/icons/like.svg'

import { default as Butterfly } from './Butterfly'

export default {
    Like,
    InfoCircle,
    ExclamationCircle,
    CloseCircle,
    CheckCircle,
    Butterfly,
    Windy,
    Sunshine,
    Sun,
    Snow,
    Thunder,
    Rain,
    Villa,
    Inbox,
    Tag,
    Bookmark,
    Book,
    Moon,
    Message,
    Heart,
    User,
    Github,
    Twitter,
    Telegram,
    Music,
    Mail,
    Anchor,
    External,
    Hash,
    Search,
    Scissors,
    Send,
    Code,
    Calendar,
    Eye,
}
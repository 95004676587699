import React from 'react';

const Loading = () => {
    return (
        <div style={{ position: 'fixed', top: '48%', left: '48%' }}>
        </div>
    );
};

export default Loading;

import SparkMD5 from 'spark-md5';
import config from "@/config";

/**
 * menu Highlight key
 * @param pathname string
 */
const queryKeysByPath = (pathname: string): { openKey: string; selectKey: string } => {
    const reg = /(^\/*)|(\/*$)/g; // 匹配字符串首尾斜杠
    const path = pathname.replace(reg, '');
    const routes = path.split('/');
    return { openKey: routes[0], selectKey: routes[1] || routes[0] };
};
export default queryKeysByPath;

/**
 * 生成随机数
 * @returns string
 */
export function randomNumber(): string {
    let outTradeNo = String(new Date().getTime());
    for (let i = 0; i < 6; i++) {
        outTradeNo += String(Math.floor(Math.random() * 10));
    }
    return outTradeNo;
}

interface WalkType {
    (tree: Record<string, any>, depth?: number, parentTree?: Record<string, any>): void;
}

// 计算文件MD5
export function getFileMd5(file: File) {
    const sliceLength = 10;
    const chunkSize = Math.ceil(file.size / sliceLength);
    const fileReader = new FileReader();
    const md5 = new SparkMD5();
    let index = 0;
    const loadFile = () => {
        const slice = file.slice(index, index + chunkSize);
        fileReader.readAsBinaryString(slice);
    };
    loadFile();
    return new Promise<string>((resolve, reject) => {
        fileReader.onload = e => {
            md5.appendBinary(e?.target?.result as any);
            if (index < file.size) {
                index += chunkSize;
                loadFile();
            } else {
                const md5Str = md5.end();
                console.log(md5Str);
                resolve(md5Str);
            }
        };
    });
}

/**
 * 函数防抖
 * @param func  函数
 * @param wait 等待时间 单位毫秒
 * @param immediate 是否立即执行
 */
export function debounce(func: any, wait: number, immediate: boolean) {
    let timeout: any;
    return function () {
        // console.log(this);  //=>从中可以测试出this指向的container
        //保存this
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this;
        // 解决前面的event指向问题
        let args = arguments;
        // 清空上从定时器
        if (timeout) clearTimeout(timeout);

        if (immediate) {
            //若执行过了,不再执行
            let callNow = !timeout; //callNow起始值为true,timeout刚开始为undefined,则作取反操作,那么就可以保证callNow初始值为true.
            timeout = setTimeout(() => {
                timeout = null; //在wait内,timeout是一个id数字,在在此期间callNow为false,func函数不会执行!
            }, wait);
            //初始为true会直接执行,在wait时间之内,callNow设置为timeout有值,则callNow为false,此时不执行,在wait时间之后,timeout设置为null,此时callNow为true开始执行
            if (callNow) func.apply(_this, args);
        } else {
            timeout = setTimeout(function () {
                // console.log(this)  //=>这里面的this指向window，也就是前面的count那的this是指向window
                //但是防抖函数的this应该是指向container
                func.apply(_this, args);
            }, wait);
        }
    };
}

/**
 * 毫秒转 *天*时*分*秒
 * @param millisecond  毫秒数
 * @return 格式化时间 ex：1天3小时0分34秒
 */
export const formatDuring = (millisecond: number) => {
    //天
    const days = Math.floor(millisecond / (1000 * 60 * 60 * 24));
    //小时
    const hours = Math.floor(millisecond / 1000 / 60 / 60 - days * 24);
    //分钟
    const minutes = Math.floor(millisecond / 1000 / 60 - days * 24 * 60 - hours * 60);
    //秒
    const seconds = Math.floor(millisecond / 1000 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
    return days + ' 天 ' + hours + ' 小时 ' + minutes + ' 分钟 ' + seconds + ' 秒 ';
};

// 通过新开窗口进行路由跳转
export const jumpPath = (path: string) => {
    const hasHash = process.env.NODE_ENV === 'production' ? '#/' : '';
    window.open(`${window.config.baseUrl + hasHash}${path.startsWith('/') ? path.slice(1) : path}`);
};

/**
 * 生成下载文件名称
 * @param {string} url
 * @returns {string} 文件名称
 */
export const generateDownloadFileName = (url: string): string => {
    const urlSliceIndex = url.lastIndexOf('/') + 1;
    const downloadFileName = window.decodeURIComponent(url.slice(urlSliceIndex));
    return downloadFileName;
};

/**
 * 下载导出url文件
 * @param {string} downLoadUrl 下载链接url
 * @param {string} fileName 文件名称
 */
export const downloadExportUrlFile = (downLoadUrl: string, fileName: string) => {
    const linkElement = document.createElement('a');
    linkElement.style.display = 'none';
    linkElement.href = downLoadUrl;
    linkElement.download = fileName;
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
};

export const arrayGenerate = (startIndex: number, endIndex: number) => {
    return [...Array(endIndex - startIndex + 1)].map((v, i) => startIndex + i);
};

export const findParentIdList = (allMenus: any[] = [], id: number, idName: string) => {
    if (!allMenus?.length) return [];
    const data: string[] = [];
    for (let i of allMenus) {
        if (i.children?.length) {
            const result = findParentIdList(i.children, id, idName);
            if (result.length) {
                data.push(...result);
                data.push(i[idName]);
            }
        } else {
            if (id == i[idName]) {
                data.push(i[idName]);
            }
        }
    }
    return data;
};


/**
 * 日期转换
 */
export const parseTime = (time: string, format = '{y}年{m}月{d}日') => {
    const date = new Date(time)
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value: string | number = formatObj[key as keyof typeof formatObj]
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = `0${value}`
        }
        return `${value}` || '0'
    })
    return time_str
}

/**
 * url 转换
 */
export const getLocation = (href: string) => {
    const a = document.createElement('a')
    a.href = href
    return a
}

const { url, author } = config

/**
 * Nya
 */
export const Nya = () => {
    const labelStyle = 'line-height:22px;color:#E5E7EB;background:#1F2937;border-radius:3px;'
    const site = url
    console.info(`%c ${author} %c`, labelStyle, '', site)
    console.info('~❀~ 发现控制台报错请务必联系博主 ~❀~')
}
// 全局共享数据示例
import { useState } from 'react';
import { name as packageName } from '@/../package.json'

const State = () => {
    const [name, setName] = useState<string>(packageName);

    return {
        name,
        setName,
    };
};

export default State;

// 运行时配置

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://next.umijs.org/docs/api/runtime-config#getinitialstate

export async function getInitialState(): Promise<{ name: string }> {
    return { name: '@umijs/max' };
}
import 'moment/locale/zh-cn';
import moment from 'moment';
moment.locale('zh-cn');
import { Nya } from '@/utils/utils'

const path = process.env.NODE_ENV === 'production' ? location.pathname.replace(/(\/)$/, "") : '';
window.config = {
    domain: location.origin,
    rootPath: path,
    baseUrl: location.origin + path,
};

Nya()


declare global {
    interface Window {
        config: Record<'domain' | 'rootPath' | 'baseUrl', any>;
    }
}

// export const layout = () => {
//     return {
//         logo: 'https://img.alicdn.com/tfs/TB1YHEpwUT1gK0jSZFhXXaAtVXa-28-27.svg',
//         menu: {
//             locale: false,
//         },
//     };
// };

// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import routeProps from './routeProps';

if (process.env.NODE_ENV === 'development') {
  Object.entries(routeProps).forEach(([key, value]) => {
    const internalProps = ['path', 'id', 'parentId', 'isLayout', 'isWrapper', 'layout', 'clientLoader'];
    Object.keys(value).forEach((prop) => {
      if (internalProps.includes(prop)) {
        throw new Error(
          `[UmiJS] route '${key}' should not have '${prop}' prop, please remove this property in 'routeProps'.`
        )
      }
    })
  })
}

import React from 'react';

export async function getRoutes() {
  const routes = {"404":{"path":"*","id":"404","parentId":"@@/global-layout"},"Post/components/Anchor/index":{"path":"Post/components/Anchor","id":"Post/components/Anchor/index","parentId":"@@/global-layout"},"Inspiration/index":{"path":"Inspiration","id":"Inspiration/index","parentId":"@@/global-layout"},"Project/index":{"path":"Project","id":"Project/index","parentId":"@@/global-layout"},"Friend/index":{"path":"Friend","id":"Friend/index","parentId":"@@/global-layout"},"About/index":{"path":"About","id":"About/index","parentId":"@@/global-layout"},"Book/index":{"path":"Book","id":"Book/index","parentId":"@@/global-layout"},"Post/index":{"path":"Post","id":"Post/index","parentId":"@@/global-layout"},"index":{"path":"/","id":"index","parentId":"@@/global-layout"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'404': React.lazy(() => import(/* webpackChunkName: "src__pages__404" */'../../../src/pages/404.tsx')),
'Post/components/Anchor/index': React.lazy(() => import(/* webpackChunkName: "src__pages__Post__components__Anchor__index" */'../../../src/pages/Post/components/Anchor/index.tsx')),
'Inspiration/index': React.lazy(() => import(/* webpackChunkName: "src__pages__Inspiration__index" */'../../../src/pages/Inspiration/index.tsx')),
'Project/index': React.lazy(() => import(/* webpackChunkName: "src__pages__Project__index" */'../../../src/pages/Project/index.tsx')),
'Friend/index': React.lazy(() => import(/* webpackChunkName: "src__pages__Friend__index" */'../../../src/pages/Friend/index.tsx')),
'About/index': React.lazy(() => import(/* webpackChunkName: "src__pages__About__index" */'../../../src/pages/About/index.tsx')),
'Book/index': React.lazy(() => import(/* webpackChunkName: "src__pages__Book__index" */'../../../src/pages/Book/index.tsx')),
'Post/index': React.lazy(() => import(/* webpackChunkName: "src__pages__Post__index" */'../../../src/pages/Post/index.tsx')),
'index': React.lazy(() => import(/* webpackChunkName: "src__pages__index" */'../../../src/pages/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/home/runner/work/blog/blog/src/layouts/index.tsx')),
},
  };
}
